<template>
    <div>
        <div v-if="false">
            <p>dashboardPage</p>
        </div>
        <router-view v-else/>
    </div>
</template>

<script>
export default {
    name: 'sales',
    modules: [
        'supplier',
        'makeAndModel',
        'purchase',
        'vehicleOptions',
        'userManagement',
    ]
}
</script>
